import React from "react"
import { graphql } from "gatsby"

import GlobalStateProvider from "context/provider"
import Layout from "components/layout"
import SEO from "components/seo"
import Hero from "components/sections/careers/hero"
import Values from 'components/sections/careers/values'
import Locations from 'components/sections/careers/locations'
import Benefits from "components/sections/careers/benefits"
import Positions from "components/sections/careers/positions"
import Video from "components/sections/careers/video"
import { seoTitleSuffix } from "config"

const CareersPage = ({ data }) => {
  const { frontmatter } = data.careers.edges[0].node

  const globalState = {
    // if useSplashScreen=false, we skip the intro by setting isIntroDone=true
    isIntroDone: frontmatter.useSplashScreen ? false : true,
    // darkMode is initially disabled, a hook inside the Layout component
    // will check the user's preferences and switch to dark mode if needed
    darkMode: false,
  }

  return (
    <GlobalStateProvider initialState={globalState}>
     <Layout headerContent={data.header.edges} footerContent={data.footer.edges} lang="fr">
        <SEO
          frontmatter={frontmatter}
          lang="fr"
        />
        <Hero content={data.hero.edges} />
        <Values  content={data.values.edges} />
        <Locations  content={data.locations.edges} />
        <Video  content={data.video.edges} />
				<Benefits content={data.benefits.edges} />
        <Positions content={data.positions.edges} />
        <div className="gradient">
        </div>
        
      </Layout>
    </GlobalStateProvider>
  )
}


export default CareersPage

export const pageQuery = graphql`
  {
    careers: allMdx(filter: { fileAbsolutePath: { regex: "/careers/fr/careers/" } }) {
      edges {
        node {
          frontmatter {
            title
            url
            description
            keywords
            useSeoTitleSuffix
            useSplashScreen
          }
        }
      }
    }
    hero: allMdx(filter: { fileAbsolutePath: { regex: "/careers/fr/hero/" } }) {
      edges {
        node {
          frontmatter {
            title
            subtitle
            buttonText
            mdxbgImage {
              childImageSharp {
                gatsbyImageData(layout: FULL_WIDTH, quality: 90)
              }
            }
            teamPhoto {
              childImageSharp {
                gatsbyImageData(layout: FULL_WIDTH, quality: 90)
              }
            }
          }
        }
      }
		}
    values: allMdx(filter: { fileAbsolutePath: { regex: "/careers/fr/values/" } }) {
      edges {
        node {
          frontmatter {
            title
          }
          exports {
            shownItems
            items {
              logo {
                childImageSharp {
                  gatsbyImageData(width: 50, layout: FIXED, quality: 90)
                }
              }
              title
              color
            }
          }
        }
      }
    }
    locations: allMdx(filter: { fileAbsolutePath: { regex: "/careers/fr/locations/" } }) {
      edges {
        node {
          frontmatter {
            title
          }
          exports {
            shownItems
            items {
              logo {
                childImageSharp {
                  gatsbyImageData(height: 100, layout: FIXED, quality: 90)
                }
              }
              title
              color
              city
              country
            }
          }
        }
      }
    }
    video: allMdx(filter: { fileAbsolutePath: { regex: "/careers/fr/video/" } }) {
      edges {
        node {
          frontmatter {
            title
          }
        }
      }
    }
		benefits: allMdx(filter: { fileAbsolutePath: { regex: "/careers/fr/benefits/" } }) {
      edges {
        node {
          frontmatter {
            title
          }
          exports {
            shownItems
            items {
              logo {
                childImageSharp {
                  gatsbyImageData(height: 80, layout: FIXED, quality: 90)
								}
								extension
								publicURL
              }
              title
              color
            }
          }
        }
      }
    }
    positions: allMdx(filter: { fileAbsolutePath: { regex: "/careers/fr/positions/" } }) {
      edges {
        node {
          frontmatter {
            title
            description
            subtitle
            applyText
          }
          exports {
            shownItems
            items {
              title
              contract
              summary
            }
          }
        }
      }
    }
    footer: allMdx(filter: { fileAbsolutePath: { regex: "/layout/fr/footer/" } }) {
      edges {
        node {
          body
          frontmatter {
            label
            title
            titlePrefix
            subtitle
            subtitlePrefix
            buttonBookingText
            buttonBookingLink
            buttonSignupText
            buttonSignupLink
            copyRight
          }
          exports {
            items {
              name
              links {
                text
                link
                external
              }
            }
          }
        }
      }
    }
    header: allMdx(filter: { fileAbsolutePath: { regex: "/layout/fr/header/" } }) {
      edges {
        node {
          frontmatter {
            demoButtonText
            demoButtonLink
            registerButtonText
            registerButtonLink
            bookingButtonText
            bookingButtonLink
          }
          exports {
            items {
              name
              modal {
                left {
                  featured {
                    name
                    items {
                      title
                      description
                      link
                      icon
                      image
                    }
                  }
                  simple {
                    name
                    items {
                      title
                      link
                      icon
                      image
                    }
                  }
                }
                right {
                  featured {
                    name
                    items {
                      title
                      description
                      link
                      icon
                      image
                    }
                  }
                  simple {
                    name
                    items {
                      title
                      link
                      icon
                      image
                    }
                  }
                  media {
                    name
                    photo
                    alt
                    text
                    link
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`
